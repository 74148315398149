(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("babylonjs"), require("babylonjs-materials"), require("babylonjs"), require("babylonjs-gui"), require("babylonjs-gui-editor"), require("babylonjs-loaders"), require("babylonjs-serializers"));
	else if(typeof define === 'function' && define.amd)
		define("babylonjs-inspector", ["babylonjs", "babylonjs-materials", "babylonjs", "babylonjs-gui", "babylonjs-gui-editor", "babylonjs-loaders", "babylonjs-serializers"], factory);
	else if(typeof exports === 'object')
		exports["babylonjs-inspector"] = factory(require("babylonjs"), require("babylonjs-materials"), require("babylonjs"), require("babylonjs-gui"), require("babylonjs-gui-editor"), require("babylonjs-loaders"), require("babylonjs-serializers"));
	else
		root["INSPECTOR"] = factory(root["BABYLON"], root["BABYLON"], root["BABYLON"]["Debug"], root["BABYLON"]["GUI"], root["BABYLON"], root["BABYLON"], root["BABYLON"]);
})((typeof self !== "undefined" ? self : typeof global !== "undefined" ? global : this), (__WEBPACK_EXTERNAL_MODULE__5597__, __WEBPACK_EXTERNAL_MODULE__4208__, __WEBPACK_EXTERNAL_MODULE__5992__, __WEBPACK_EXTERNAL_MODULE__5490__, __WEBPACK_EXTERNAL_MODULE__4325__, __WEBPACK_EXTERNAL_MODULE__2842__, __WEBPACK_EXTERNAL_MODULE__513__) => {
return 